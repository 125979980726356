import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import { exclusionParams } from 'redux/modules/Eligibility/constants';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { ApplicationApi } from 'redux/modules/App/api';
import { EntryFormApi } from 'redux/modules/EntryForm/api';

import getDecision from 'app/lib/analytics/Optimizely/getDecision';
import { features, variations } from 'app/lib/analytics/Optimizely/features';

function getIsExcluded(state, product) {
  const params = ApplicationApi.getExcludeParams(state);
  return params.includes(product);
}

export const isEligibleForBoilerCover = (state) => {
  const { variant } = getDecision(features.HIDE_BHC_BOILER_SERVICE);

  return (
    EntryFormApi.isHomeOwner(state) &&
    variant === variations[features.HIDE_BHC_BOILER_SERVICE.OFF]
  );
};

export const isBoilerCoverExcluded = (state) =>
  getIsExcluded(state, exclusionParams.BOILER);

export const isEligibleForBip = (state) => {
  const isOrganic = ApplicationApi.isOrganicApplication(state);
  const isSales = ApplicationApi.isSalesApplication(state);
  const eligibleForBillProtector = EligibilityServiceApi.isEligibleForBillProtector(
    state
  );
  const eligibleForIncomeProtector = EligibilityServiceApi.isEligibleForIncomeProtector(
    state
  );

  return (
    (eligibleForBillProtector ||
      eligibleForIncomeProtector ||
      isOrganic ||
      isSales) &&
    FeatureFlagsApi.getFlag(state, FLAGS.BILL_INCOME_PAGE) &&
    !FeatureFlagsApi.getFlag(state, FLAGS.INSURANCE_PROTECTORS_DISABLED)
  );
};

export const isBillIncomeExcluded = (state) =>
  getIsExcluded(state, exclusionParams.BILL_INCOME_PROTECTOR);
