import axios from 'axios';
import { isEmpty } from 'lodash';
import { API_URL } from 'config';
import { partnerAuthorizedPost } from 'redux/utils/request';

export const createPartnerApplication = (
  customerRelationship,
  referrer,
  supportingPartnerId,
  supportingPartnerToken,
  hostedId,
  lead
) => {
  const payload = {
    type: customerRelationship,
  };

  if (!isEmpty(referrer)) {
    payload.referrer = referrer;
  }

  if (supportingPartnerId) {
    payload.supportingPartnerId = supportingPartnerId;
    payload.supportingPartnerToken = supportingPartnerToken;
  }

  if (hostedId) {
    payload.hostedId = hostedId;
  }

  if (lead && lead.id) payload.leadId = lead.id;
  if (lead && lead.code) payload.leadCode = lead.code;

  return partnerAuthorizedPost('application', payload);
};

export const createOrganicApplication = (hostedId, leadCode) => {
  return axios.create().request({
    url: `${API_URL}/application`,
    method: 'post',
    data: {
      hostedId,
      leadCode,
    },
  });
};

export const createSalesApplication = (token, leadId = null) => {
  return axios.create().request({
    url: `${API_URL}/sales/application`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      leadId,
    },
  });
};

export const createSalesApplicationIAM = (token, leadId = null) => {
  return axios.create().request({
    url: `${API_URL}/sales/application/iam`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      leadId,
    },
  });
};

export const createSalesApplicationTTJWT = (id_token) => {
  return axios.create().request({
    url: `${API_URL}/tt/application/jwt`,
    method: 'post',
    data: {
      id_token,
    },
  });
};

export const createRemoteApplication = (
  remotePartnerId,
  customerRelationship,
  referrer,
  lead
) => {
  const payload = {
    type: customerRelationship,
    remotePartnerId,
  };

  if (!isEmpty(referrer)) {
    payload.referrer = referrer;
  }

  if (lead && lead.id) payload.leadId = lead.id;
  if (lead && lead.code) payload.leadCode = lead.code;

  return partnerAuthorizedPost('application/remote', payload);
};

export const createPartnerApplicationRemoteSupport = (
  remotePartnerId,
  customerRelationship,
  referrer,
  hostedId,
  lead
) => {
  const payload = {
    type: customerRelationship,
    remotePartnerId,
  };

  if (!isEmpty(referrer)) {
    payload.referrer = referrer;
  }

  if (hostedId) {
    payload.hostedId = hostedId;
  }

  if (lead && lead.id) payload.leadId = lead.id;
  if (lead && lead.code) payload.leadCode = lead.code;

  return partnerAuthorizedPost('application/half-remote', payload);
};
